import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {NotificationsComponent} from '../notifications/notifications.component';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const CLIENT_KEY = 'cliente-selected';
const APARTADOS_KEY ='apartados-hidden-'
const LESION_KEY ='lesion-'
const CLIENT_OPTIONS_KEY = 'cliente-options-';
const SCROLL_KEY = 'scroll-key';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    constructor() {
    }

    signOut(): void {
        window.sessionStorage.clear();
    }

    public saveToken(token: string): void {
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string {
        return sessionStorage.getItem(TOKEN_KEY);
    }

    public saveRefreshToken(token: string): void {
        window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
        window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
    }

    public getRefreshToken(): string | null {
        return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
    }

    public saveUser(user, notifications: NotificationsComponent): void {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
        const socket = new SockJS(environment.serverUrl + '/our-websocket');
        const stompClient = Stomp.over(socket);
        stompClient.connect({}, function (frame) {


            // var suscription = stompClient.subscribe('/topic/global-notifications', function (message) {
            //     notifications.showSuccessNotification(JSON.parse(message.body).content);
            // });

            stompClient.subscribe('/topic/private-notifications/' + user.username, function (message) {
                notifications.showSuccessNotification(JSON.parse(message.body).content);
            });
        });
    }

    public activateStomp(notifications: NotificationsComponent) {


        const username = this.getUser() !== undefined ? this.getUser().username : null;

        if (username != null) {
            const socket = new SockJS(environment.serverUrl + '/our-websocket');
            const stompClient = Stomp.over(socket);

            stompClient.connect({}, function (frame) {

                // var suscription = stompClient.subscribe('/topic/global-notifications', function (message) {
                //   notifications.showSuccessNotification(JSON.parse(message.body).content);
                // });

                stompClient.subscribe('/topic/private-notifications/' + username, function (message) {
                    notifications.showSuccessNotification(JSON.parse(message.body).content);
                });
            });
        }

    }

    public getUser(): any {
        return JSON.parse(sessionStorage.getItem(USER_KEY));
    }

    public saveClient(client): void {
        window.sessionStorage.removeItem(CLIENT_KEY);
        window.sessionStorage.setItem(CLIENT_KEY, JSON.stringify(client));
    }

    public saveClientOptions(options): void {
        window.sessionStorage.removeItem(CLIENT_OPTIONS_KEY);
        window.sessionStorage.setItem(CLIENT_OPTIONS_KEY, JSON.stringify(options));
    }

    public getClient(): any {
        return JSON.parse(sessionStorage.getItem(CLIENT_KEY));
    }

    public getClientOptions(): any {
        return JSON.parse(sessionStorage.getItem(CLIENT_OPTIONS_KEY));
    }

    public saveApartadosHidden(apartadosHidden,requestMapping): void {
        window.sessionStorage.removeItem(APARTADOS_KEY + requestMapping);
        window.sessionStorage.setItem(APARTADOS_KEY + requestMapping, JSON.stringify({'apartados':apartadosHidden}));
    }


    public getApartadosHidden(requestMapping): any {
        var apartados =  JSON.parse(window.sessionStorage.getItem(APARTADOS_KEY + requestMapping));
        if(apartados != undefined){
            return apartados.apartados;
        }
    }

    public saveGraficoLesion(tipoGrafico,x,y): void {
        window.sessionStorage.removeItem(LESION_KEY + tipoGrafico);
        window.sessionStorage.setItem(LESION_KEY + tipoGrafico, JSON.stringify(x+"-"+y));
    }

    public getGraficoLesion(tipoGrafico): any {
       return JSON.parse(window.sessionStorage.getItem(LESION_KEY + tipoGrafico));

    }
    
    public emptyGraficoLesion(tipoGrafico): void {
        window.sessionStorage.removeItem(LESION_KEY + tipoGrafico);
    }

    public saveScroll(requestMapping,scroll): void {
        window.sessionStorage.removeItem(SCROLL_KEY + requestMapping);
        window.sessionStorage.setItem(SCROLL_KEY + requestMapping, JSON.stringify(scroll));
    }

    public getScroll(requestMapping): any {
       return JSON.parse(window.sessionStorage.getItem(SCROLL_KEY + requestMapping));

    }
}
