import {Component, OnInit} from '@angular/core';
import {AuthService} from '../_services/auth.service';
import {TokenStorageService} from '../_services/token-storage.service';
import {catchError, map} from 'rxjs/operators';
import {merge, of as observableOf, throwError} from 'rxjs';
import {NotificationsComponent} from '../notifications/notifications.component';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    form: any = {};
    isLoggedIn = false;
    isLoginFailed = false;
    isEmailSent = false;
    isEmailSentRight = false;
    errorMessage = '';
    roles: string[] = [];
    isEnabledButtonRemember = !environment.ldap;
    environment = environment;
    isMFA = false;
    pwd = null;
    hide=true;

    public ts;

    constructor(
        private authService: AuthService,
        private tokenStorage: TokenStorageService,
        public notifications: NotificationsComponent,
        private router: Router,
        private translate: TranslateService) {
        this.ts = translate;
    }

    ngOnInit(): void {
        if (this.tokenStorage.getToken()) {
            this.isLoggedIn = true;
            this.roles = this.tokenStorage.getUser().roles;
        }
        else{
            this.translate.use(this.environment.defaultLanguage);
        }
    }

    onSubmit(): void {

        var self = this;
        if(!this.isMFA){
            this.authService.login(this.form).subscribe(
                data => {
    
                    if(data.mfa){
                        self.isMFA=true;
                        self.pwd = self.form.password;
                        self.tokenStorage.saveUser(data, this.notifications);
                        
                        self.roles = self.tokenStorage.getUser().roles;
                        if (self.tokenStorage.getUser().language != null) {
                            self.translate.use(self.tokenStorage.getUser().language);
                        }
                    }
                    else{
                        self.tokenStorage.saveToken(data.token);
                        self.tokenStorage.saveRefreshToken(data.refreshToken);
                        self.tokenStorage.saveUser(data, this.notifications);
                        
                        self.roles = self.tokenStorage.getUser().roles;
                        if (self.tokenStorage.getUser().language != null) {
                            self.translate.use(self.tokenStorage.getUser().language);
                        }
                        
                        self.isLoginFailed = false;
                        self.isLoggedIn = true;
                        self.router.navigate([environment.initPrivateUrl]);
                    }
                    
                },
                err => {
                    if (err.error === 'User credentials have expired') {
                        this.notifications.showDangerNotification(self.getTs().instant('error.credentialsExpired'));
                        this.authService.resetPasswordToken(this.form.username).pipe(
                            catchError(error => {
                                return throwError('Error enviando a pagina de reseteo de contraseña');
                            })
                        ).subscribe(data => {
                            window.location.replace(data);
                        }, error => {
                            this.notifications.showDangerNotification(self.getTs().instant('error.resetPassword'));
                            return observableOf([]);
                        });
                    } else {
                        if (err.error === 'User account is locked') {
                            this.errorMessage = self.getTs().instant('error.accountLocked');
                        } else if (err.error === 'Bad credentials') {
                            this.errorMessage = self.getTs().instant('error.badCredentials');
                        } else {
                            this.errorMessage = self.getTs().instant('error.logginError');
                        }
                        this.isLoginFailed = true;
                        return observableOf([]);
                    }
                }
            );
        }
       else{
        this.authService.verify(this.form, this.pwd).subscribe(
            data => {
               
                this.pwd=null;
                this.tokenStorage.saveToken(data.token);
                this.tokenStorage.saveRefreshToken(data.refreshToken);
                this.router.navigate([environment.initPrivateUrl]);
             
                
            },
            err => {
                if (err.error === 'User credentials have expired') {
                    this.notifications.showDangerNotification(self.getTs().instant('error.credentialsExpired'));
                    this.authService.resetPasswordToken(this.form.username).pipe(
                        catchError(error => {
                            return throwError('Error enviando a pagina de reseteo de contraseña');
                        })
                    ).subscribe(data => {
                        window.location.replace(data);
                    }, error => {
                        this.notifications.showDangerNotification(self.getTs().instant('error.resetPassword'));
                        return observableOf([]);
                    });
                } else {
                    if (err.error === 'User account is locked') {
                        this.errorMessage = self.getTs().instant('error.accountLocked');
                    } else if (err.error === 'Bad credentials') {
                        this.errorMessage = self.getTs().instant('error.badCredentials');
                    } else if (err.error === 'Code Wrong') {
                        this.errorMessage = self.getTs().instant('error.badCredentialsCode');
                    }else {
                        this.errorMessage = self.getTs().instant('error.logginError');
                    }
                    this.isLoginFailed = true;
                    return observableOf([]);
                }
            }
        );
       }

       
    }

    forgotPassword(): void {

        var self = this;
        this.authService.forgotPassword(this.form.username).pipe(
            catchError(err => {
                return throwError('Error enviando email');
            })
        ).subscribe(next => {
            this.notifications.showSuccessNotification(self.getTs().instant('success.sentRemember'));
        }, err => {
            this.notifications.showDangerNotification(self.getTs().instant('success.sentRemember2'));
            return observableOf([]);
        });
    }

    reloadPage(): void {
        window.location.reload();
    }

    getTs() {
        return this.ts;
    }
}
